.App {
  text-align: center;
  font-family: 'JetBrains Mono';
  font-size: small;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@keyframes header-fade {
  0% {
    background-color: #282c3400;
  }
  100% {
    background-color: #282c34ef;
  }
}

.App-header {
  background-color: #282c34ef;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 2vh;
  animation: header-fade 1 5s linear;
  z-index: -1;
}

.App-link {
  color: #61dafb;
}

.xhead {
  width: 50vw;
}

.LB-card {
  border-radius: 20px;
  box-shadow: 2px;
  background-color: rgba(0, 0, 0, 0.39);
  align-items: center;
  padding: 40px;
  max-width: 85vw;
}

.bg-blackish {
  background-color: rgba(0, 0, 0, 0.50);
}

.backblur {
  backdrop-filter: blur(6px);
}

.rank-gold {
  background: linear-gradient(0deg, rgba(255,215,0,0) 0%, rgba(255,215,0,0.25) 100%);
}
.rank-silver {
  background: linear-gradient(0, rgba(186,186,186,0) 0%, rgba(186,186,186,0.25) 100%);
}
.rank-bronze {
  background: linear-gradient(0, rgba(205,127,50,0) 0%, rgba(205,127,50,0.25) 100%);
}
.rank-zero {
  background: linear-gradient(0, rgba(57,57,57,0) 0%, rgba(57,57,57,0.25) 100%);
}

.lvl-icon {
  height: 25px;
  width: auto;
}

.title-icon {
  height: 100px;
  width: auto;
}

.table td, .table th {
  border-top: 0px;
}

.table thead th {
  border-bottom: 0px;
  font-size: x-small;
}

.table {
  max-width: 85vw;
}

.x-shadow {
  box-shadow: 0 0px 40px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1225px) and (min-width: 1045px) {
  .priority-5{
    display:none;
  }
}

@media screen and (max-width: 1045px) and (min-width: 835px) {
  .priority-5{
    display:none;
  }
  .priority-4{
    display:none;
  }
}

@media screen and (max-width: 565px) and (min-width: 300px) {
  .priority-5{
    display:none;
  }
  .priority-4{
    display:none;
  }
  .priority-3{
    display:none;
  }
}

@media screen and (max-width: 300px) {
  .priority-5{
    display:none;
  }
  .priority-4{
    display:none;
  }
  .priority-3{
    display:none;
  }
  .priority-2{
    display:none;
  }

}

.nav-link {
  padding: 0px;
}

.nav-icon {
  height: 30px;
  width: auto;
  padding: 0px;
  opacity: 40%;
}

@keyframes navicon-animation {
  0% {
    opacity: 40%; 
  }
  90% {
    opacity: 90%;
  }
  92% {
    opacity: 50%; 
  }
  100% {
    opacity: 100%;
  }
}


.active > img {
  opacity: 100%;
  animation: navicon-animation 1 1s linear;
}

@keyframes loadlater-animation {
  0% {
    opacity: 0%;
    transform: translate(0vh, 20vh);
  }
  80% {
    opacity: 5%;
    transform: translate(0vh, 20vh);
  }
  100% {
    opacity: 98%;
    transform: translate(0vh, 0vh);
  }
}

#theTopNav {
  opacity: 98%;
  animation: loadlater-animation 1 5s linear
}

.oneline-name {
  white-space: nowrap;
  text-overflow: ellipsis;
}

@keyframes xhead-splash-anim {
  0% {
    opacity: 5%;
    transform: scale(6);
  }
  90% {
    opacity: 10%;
    transform: scale(3);
  }
  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

.xhead-splash {
  animation: xhead-splash-anim 1 0.5s linear;
}

#rxg {
  color: #81ff00;
}

#credby {
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: xx-small;
  padding: 2vh;
}

#credby > p > img {
  width: auto;
  height: 15px;
}

@keyframes appear-a {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

.appear-a {
  animation: appear-a 1 2s linear;
}

.text-timehash {
  letter-spacing: 10px;
  font-size: xx-small;
  font-weight:lighter
}

.z0 {
  z-index: 0;
}

.z1 {
  z-index: 1;
}

.z2 {
  z-index: 2;
}